import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import get from 'lodash/get';

import { styleConfig } from '../../config/config';

import Title from './Common/Title';

const ContentModuleImageText: React.FunctionComponent<ContentModuleImageTextProps> = (props) => {
  const {
    dark,
    panelTitle,
    panelContent,
    panelImage,
    isReverse,
    isFirstItem,
    isMulti,
    multiTitle,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      position: 'relative',
      background: dark ? styleConfig.color.bg.darkgrey : '#ffffff',
    },
    noPadding: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
    content: {
      width: '85%',
      height: '100%',
      margin: '0 auto',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      padding: '100px 0',

      [theme.breakpoints.down('md')]: {
        padding: '6rem',
        width: '100%',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '4rem',
      },

      '& .inner': {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          padding: '8rem',
        },
      },
    },
    image: {
      position: 'relative',
      display: 'block',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      minHeight: 673,

      [theme.breakpoints.down('md')]: {
        minHeight: 709,
      },

      [theme.breakpoints.down('sm')]: {
        minHeight: 400,
      },
    },
    row: {
      fontWeight: 600,
    },
  }));

  const classes = useStyles();
  const fallbackImage = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "car-placeholder.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  `);

  const image = get(
    panelImage,
    'localFile.childImageSharp.gatsbyImageData',
    fallbackImage.file.childImageSharp.gatsbyImageData
  );

  return (
    <section className={classes.root}>
      <Grid container className={classes.row} direction={isReverse ? 'row-reverse' : 'row'}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <div className={clsx([classes.content, 'inner'])}>
            {panelTitle ? (
              <Title
                component="h2"
                marginBottom={40}
                titleColor={dark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey}
                titleHeavy
                titleSize="h4"
                titleText={panelTitle}
              />
            ) : (
              isMulti &&
              isFirstItem &&
              multiTitle && (
                <Title
                  component="h2"
                  marginBottom={40}
                  titleColor={dark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey}
                  titleHeavy
                  titleSize="h3"
                  titleText={multiTitle}
                />
              )
            )}

            {panelContent && (
              <Typography component="div" dangerouslySetInnerHTML={{ __html: panelContent }} />
            )}
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <GatsbyImage alt="" image={image} loading="eager" className={classes.image} />
        </Grid>
      </Grid>
    </section>
  );
};

interface ContentModuleImageTextProps {
  dark?: boolean;
  panelTitle?: string;
  panelContent: string;
  panelImage: any;
  isReverse?: boolean;
  isFirstItem?: boolean;
  isMulti?: boolean;
  multiTitle?: string;
}

export default ContentModuleImageText;
